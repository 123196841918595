@media (max-width:768px){
  .profile {
     left: 0px !important;
  }
  .top-sec-toottip {
    right: 60px;
    top: 100px;
  }
}
@media (max-width:991px){
  .big-bg {
    display: none;
  }
  .front-left {
    padding: 15px 25px;
    padding-top: 75px;
}
.log-header {
    margin-top: 50px;
}
.log-header h1 {
    font-size: 24px;
}
.log-header h3 {
    font-size: 20px;
}
.profile {
  left: 0px;
}
}

@media (max-width:1280px){

    .log-form.new-form-pd{
      margin-top: 40px !important;
    }

    .dnthaveacc.header-part-pd {
      margin-top: 40px !important;
    }
    .log-header.header-part-pd {
      margin-top: 50px !important;
    }
}

@media (max-width:1440px){
    .log-form.new-form-pd{
      margin-top: 40px !important;
    }
    .header-part-pd {
      margin-top: 30px !important;
    }
}


@media (min-width:375px) and (max-width:550px){
  .pure-top {
    left: -17px;
    top: -215%;
}
  .top-tooltip-ab {
    right: 20px;
}

  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    width: 30px !important;

  }
  .react-datepicker {
    margin-top: 10px;
  }
    .profile {
      left: 0px;
    }
}

@media (min-width:300px) and (max-width:992px){

  .careplan-head-menu ul {
    float: none !important;
    margin-bottom: 25px !important;
    margin-left: -10px !important;
  }

  .careplan-head-menu {
      text-align: left;
  }

  .careplan-user {
    width: 100% !important;
  }

  .mb-bottom-25 {
    margin-bottom: 25px;
  }

  .careplan-head-menu ul li {
     margin-bottom: 0 !important;
  }

  .dashboard-inner-head.dashboard-head-flex .dashboard-head-search {
    margin-top: 15px;
  }

}

@media (min-width:300px) and (max-width:550px){ 

  .dashboard-head-flex {
    display:block !important;
  }

  .dashboard-inner-head.dashboard-head-flex h2 {
    width: 100% !important;
  }

  .dashboard-head-search {
    position: relative !important;
    top: 0px !important;
  }

  .dashboard-head-search input {
    width: 100% !important;
    float: left !important;
  }

}